<template>
<div class="concerns">
  <div v-for="(item, index) in taskList" :key="index" class="item">
    <div :class="['item-r',isTaskOk(item) ? 'success': '']">
      <div class="title">
        <span class="yellowTxt">{{ item.taskName }}
          <span v-if="item.taskCode==='ORDER_PAY'"> - {{ detail.installmentsFlag===1? $t('分期'): $t('全款') }}</span>
        </span>
        <van-icon v-if="isTaskOk(item)" name="passed" color="#029640"/>
      </div>
      <div class="content">
        <!-- 金融审核补充资料 || 金融贷资料-->
        <p v-if="item.taskCode!=='ORDER_PAY'" class="txt" v-html="item.taskDesc"></p>
        <div class="btn-line">
        <template v-if="['FINANCE_MATERIAL'].includes(item.taskCode) && isDeliverSalesOwner">
          <div class="btn" @click.stop="dateUpload(item)">{{ item.taskStatus === '4004003' ? $t('查看资料') : $t('资料上传') }}</div>
        </template>
        <!-- PDI -->
        <template v-if="item.taskCode === 'PDI_CONFIRM' && operatePdi">
          <div class="btn" @click="escalation(item)">{{ $t('PDI信息查看') }}</div>
        </template>
        <!-- 交车排程 -->
        <template v-if="item.taskCode === 'DELIVERY_BOOK' && item.taskStatus != '4004003'&& isDeliverSalesOwner">
          <div class="btn" @click="scheduling(item)">{{$t('发起预约')}}</div>
        </template>
        <!-- 交车记录 -->
        <template v-if="item.taskCode === 'DELIVERY_RECORD' && isDeliverSalesOwner">
          <!-- 4004005记录更新 4004003查看记录 4004002交车记录上传 已上传  -->
          <div class="btn" @click="record(item)">{{ item.taskStatus === '4004002' ? $t('交车记录上传') : item.taskStatus === '4004003' ? $t('查看记录') : item.taskStatus === '4004005' ? $t('记录更新') : $t('已上传') }}</div>
        </template>
        <!-- 礼品记录上传 -->
        <template v-if="item.taskCode === 'DELIVERY_GIFT' && isDeliverSalesOwner">
          <div class="btn" @click="gift(item)">{{ item.taskStatus === '4004002' ? $t('礼品记录上传') : $t('已上传') }}</div>
        </template>
        <!-- 交车确认书 -->
        <template v-if="item.taskCode === 'DELIVERY_CONTRACT'&&isDeliverSalesOwner">
          <div class="btn">
            <span v-if="item.taskStatus === '4004003'" @click="contract(item)">{{$t('确认书查看')}}</span>
            <span v-else @click="onSign">{{$t('确认书签署')}}</span>
          </div>
        </template>
        <!-- 金融贷后材料上传 -->
        <template v-if="item.taskCode === 'LOAN_MATERIAL_COMMIT' && isDeliverSalesOwner">
          <div class="btn" @click="loanMaterial(item)">{{ item.taskStatus === '4004002' ? $t('资料上传') : $t('已上传') }}</div>
        </template>
        <!-- 发票 -->
        <template v-if="item.taskCode === 'DELIVERY_INVOICE' && isDeliverSalesOwner&&detail.invoiceStatus!=='202400'">
          <div class="btn" @click="bill(item)">
            <span v-if="item?.executionJson?.invoiceUrl">{{ $t('查看发票')  }}</span>
            <span v-if="detail.invoiceStatus==='202402'&&!item?.executionJson?.invoiceUrl">{{ $t('发票上传')  }}</span>
            <span v-if="detail.invoiceStatus==='202401'&&item.taskStatus==='4004004'">{{ $t('查看开票申请')  }}</span>
            <span v-if="detail.invoiceStatus==='202410'&&item.taskStatus=='4004002'">{{ $t('申请开票')  }}</span>
          </div>
        </template>
        <!-- 上牌信息确认 -->
        <template v-if="item.taskCode === 'REGISTRATION_CONFIRM'&& isDeliverSalesOwner">
          <div class="btn" @click="vehicleLicense(item)">
            {{ item.taskStatus === '4004002' ? $t('车辆信息登记') : $t('车辆信息查看') }}
          </div>
        </template>
        <!-- 车辆临牌办理 -->
        <template v-if="item.taskCode === 'TEMP_LICENSE_APPLICATION'&& isDeliverSalesOwner">
          <div class="btn" @click="tempLicense(item)">{{ item.taskStatus === '4004002' ?  $t('临牌信息登记') : $t('临牌变更') }}</div>
        </template>
        <!-- 正式牌办理确认 -->
        <template v-if="item.taskCode === 'LICENSE_APPLICATION'&& isDeliverSalesOwner">
          <div class="btn" @click="officialLicense(item)">{{ item.taskStatus === '4004002' ? $t('信息登记') : $t('信息更新') }}
          </div>
        </template>
        <!-- 品牌保险购买 -->
        <template v-if="item.taskCode === 'BRAND_INSURANCE_PURCHASE_REMIND'&& isDeliverSalesOwner">
          <div class="btn" @click="purchaseInsurance(item)">
            {{ item.taskStatus === '4004002' ? $t('购买意愿确认') : $t('已确认') }}
          </div>
        </template>
        <!-- SIM认证&车辆绑定 -->
        <template v-if="['VEHICLE_BIND'].includes(item.taskCode)&& isDeliverSalesOwner">
          <div v-if="item.taskStatus === '4004002'" class="btn" @click="deliveryExecution(item)">
            {{ $t('执行确认') }}
          </div>
        </template>
        <!-- 交车任务准备 -->
        <template v-if="item.taskCode === 'DELIVERY_MATERIAL'&&(isDeliverSalesOwner||operateMaterial)">
          <div class="btn" @click="onVehicleReady(item)">
            {{ item.taskStatus === '4004002'?$t('整备确认'):item.taskStatus === '4004001'? $t('整备未完成'): item.taskStatus ==='4004004'? $t('验收确认') : $t('整备完成') }}
          </div>
        </template>
        <!-- 车款支付 -->
        <template v-if="item.taskCode === 'ORDER_PAY'">
          <OrderPay :details="detail" :isDeliverSalesOwner="isDeliverSalesOwner"></OrderPay>
        </template>
        <!-- 新保任务 -->
        <template v-if="item.taskCode === 'INSURANCE_MATERIAL_UPLOAD'&&isDeliverSalesOwner">
          <div class="btn" @click="insurance(item)">
            {{ item.taskStatus === '4004003' ? $t('查看资料') : item.taskStatus === '4004004'? $t('更新资料'):$t('资料提交') }}
          </div>
        </template>
      </div>
      </div>
    </div>
  </div>
  <Empty v-if="taskList.length == 0" :description="$t('暂无数据')"></Empty>
  <!-- 确认书签署方式 -->
  <van-popup v-model="show" closeable close-icon="close" :overlay="false" position="bottom" class="sign-popup">
    <div class="sign-type">
      <div class="btn-text" @click="siginSelect('offline')">
        <p>{{$t('线下签署')}}</p>
        <van-icon name="records-o" color="#FF7D00" size="56" />
      </div>
      <div class="btn-text" @click="siginSelect('online')">
        <p>{{$t('线上签署')}}</p>
        <van-icon name="chat-o" color="#00C800" size="56" />
      </div>
    </div>
    <div><van-button round plain type="default"  class="btn btn-item" @click="onPreview">{{ $t('交付单预览') }}</van-button></div>
    <div><van-button round type="primary"  class="btn  btn-item" @click="show=false">{{ $t('取消') }}</van-button></div>
  </van-popup>
  <van-popup v-model="showBatch">
      <BatchOrderSelect v-if="showBatch" 
        :current-deliver="detail"
        :deliverList="deliverList"
        :title="'批量签署提醒'"
        :subTitle="'同一合同下还有以下订单可以进行签署，是否进行批量签署'"
        :confirmBtnText="'签署'"
        @confirm="onBatchSelect" 
        @cancel="showBatch = false"/>
  </van-popup>
</div>
</template>
<script>
import deliveryServices from '@/services/deliveryServices.js'
import loading from '@/utils/loading'
import { dateFormat } from '@/utils'
import { mapGetters } from 'vuex'
import orderServices from '@/services/orderServices'
import OrderPay from './order-pay.vue'
import BatchOrderSelect from './batch-order-select.vue'

let vm
export default {
  components:{ OrderPay,BatchOrderSelect },
  filters: {
    timeText(data) {
      return dateFormat(data, 'YYYY-MM-DD HH:mm:ss')
    },
    statusFormat(val,taskCode){
      // 金融办理提醒 与 车款支付提醒 不需要展示
      if (['DELIVERY_FINANCE_REMIND','DELIVERY_BALANCE_REMIND'].includes(taskCode)){
        return ''
      }
      // pdi任务展示 “未执行” “未通过” “通过”
      if (taskCode==='PDI_CONFIRM'){
        return val==='4004002' ? vm.$t('未执行') : val==='4004001' ? vm.$t('未通过') : vm.$t('通过')
      }
      // 交车仪式记录展示“未审核”、“未执行”、“已完成”
      if (taskCode==='DELIVERY_RECORD'){
        const map = {
          4004001: vm.$t('未通过'),
          4004002: vm.$t('未执行'),
          4004003: vm.$t('已通过'),
          4004004: vm.$t('未审核'),
          4004005: vm.$t('驳回')
        }
        return map[val]
      }
      // 其他任务
      const statusMap = {
        4004001: vm.$t('未完成'),
        4004002: vm.$t('未执行'),
        4004003: vm.$t('已完成'),
      }
      return statusMap[val]
    },
  },
  props: {
    detail: {
      type: Object,
      default: () => ({})
    },
    isDeliverSalesOwner: { // 交付专员 且当前数据 是自己的数据
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      taskList: [],
      queryMark: false,
      show: false,
      contractInfo: {
        id: '',
        contractViewUrl: '',
        contractSignUrl: '',
        contractStatus: ''
      },
      isShowPreTask: false,
      deliverList: [],
      showBatch: false
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    operatePdi() {
      // 交付专员操作自己的 PDI专员 操作所有的
      const { roleLogos = [], id } = this.userInfo
      const { deliveryUserId,authedDeliveryUserId } = this.detail
      return (roleLogos.includes('1014006') && [deliveryUserId,authedDeliveryUserId].includes(id)) || roleLogos.includes('1014009')
    },
    operateMaterial(){
      const { roleLogos = [] } = this.userInfo
      return roleLogos.includes('1014029')
    }
  },
  watch: {
    detail: {
      handler(val) {
        if (val.id) {
          this.getDeliverTask(val.id)
        }
      },
      immediate: true
    }
  },
  mounted() {
    vm = this
  },
  methods: {
    async getDeliverTask(orderId) {
      loading.showLoading()
      const res = await deliveryServices.deliveryTask({ orderId })
      loading.hideLoading()
      this.queryMark = true
      this.taskList = res || []
    },
    // PDI
    escalation({ taskConfigId, id: taskId, taskStatus }) {
      this.$router.push({
        path: '/deliver-pdiEscalation',
        query: {
          id: this.$route.query.id,
          operation: taskStatus === '4004002' ? 'edit' : 'view',
          taskConfigId,
          taskId,
          operatePdi: this.operatePdi
        }
      })
    },
    // 发起预约
    scheduling(item) {
      const { id: taskId,executionJson }=item
      const { bookDate,bookTimeStart,bookTimeEnd } = executionJson||{}
      const { appOrderId,dcosOrderId,orderType, vin,bookDeliveryTime,
        carOrderMaterial: { seriesName, modelName,colourName,interiorName }, 
        carRegistration:{ ownerName } } = this.detail
      this.$router.push({
        path: '/pickCarScheduling',
        query: {
          appOrderId: orderType===3?appOrderId:dcosOrderId,
          seriesName,
          modelName,
          vin,
          id: this.$route.query.id,
          taskId,
          bookDate,
          bookTimeStart,
          bookTimeEnd,
          ownerName,
          colourName,
          interiorName,
          bookDeliveryTime
        }
      })
    },
    // 交车记录
    record(data) {
      this.$router.push({
        name: 'deliverRecord',
        query: {
          taskId: data.id,
          orderId: this.$route.query.id,
          operation: ['4004002','4004005'].includes(data.taskStatus) ? 'edit' : 'view',
          taskStatus: data.taskStatus,
          taskDesc: data.taskDesc
        }
      })
    },
    // 礼品记录
    gift({ id, taskStatus }) {
      this.$router.push({
        name: 'giftRecord',
        query: {
          taskId: id,
          orderId: this.$route.query.id,
          operation: taskStatus === '4004002' ? 'edit' : 'view',
        }
      })
    },
    // 确认书合同详情
    async getContractDetails() {
      const params = {
        orderId: this.$route.query.id,
        contractType: 2, // 1 购车 2交车
      }
      const res = await orderServices.orderContractDetails(params)
      this.$set(this, 'contractInfo', res)
    },
    /**
     * 查看交车确认书
     */
    async contract({ id, taskStatus, executionJson }) {
      // 查看交车确认书
      const { contractType,contractUrl } = executionJson
      if (contractType === 1) {
        this.$router.push({
          name: 'deliverContract',
          query: {
            taskId: id,
            orderId: this.$route.query.id,
            operation: 'view',
          }
        })
      } else {
        const url = contractUrl
        if (this.$isMobile) {
          window.location.href = url
        } else {
          window.open(url, 'myWindow')
        }
      }
    },
    // 金融贷后材料
    loanMaterial({ id, taskStatus }) {
      const contractTask = this.taskList.find(item => item.taskCode === 'DELIVERY_CONTRACT')
      if (!contractTask || contractTask?.taskStatus === '4004002') {
        this.$toast.fail(this.$t('当前客户未签署交车确认函，请在客户签署成功后，再进行贷后资料上传'))
        return
      }
      if (!this.detail?.invoice?.einvoiceUrl){
        this.$toast.fail(this.$t('请先完成车辆发票任务并上传发票'))
        return
      }
      this.$router.push({
        name: 'loanMaterial',
        query: {
          taskId: id,
          orderId: this.$route.query.id,
          operation: taskStatus === '4004002' ? 'edit' : 'view',
          contractTaskId: contractTask.id,
          einvoiceUrl: this.detail.invoice.einvoiceUrl
        }
      })
    },
    // 资料上传
    dateUpload(item) {
      const { taskCode, id: taskId } = item
      this.$router.push({
        path: '/deliver-data-upload',
        query: {
          // taskCode,
          taskId,
          id: this.$route.query.id,
        }
      })
    },
    // 开票
    bill(item) {
      const { taskCode, id: taskId, taskStatus } = item
      const { orderType } = this.detail // 车款支付状态
      this.$router.push({
        path: '/deliver-bill',
        query: {
          taskCode,
          taskId,
          operation: taskStatus === '4004002' ? 'edit' : 'view',
          id: this.$route.query.id,
          taskStatus,
          orderType
        }
      })
    },
    onSign(){
      if ([1,2,3].includes(this.detail?.lockStatus)) {
        this.$toast(this.$t('交付单已锁定,暂无法操作'))
        return
      }
      this.show = true
    },
    // 选择签署方式
    async siginSelect(type) {
      const { id, taskStatus } = this.taskList.find(item => item.taskCode === 'DELIVERY_CONTRACT')
      if (type === 'offline') {
        this.$router.push({
          name: 'deliverContract',
          query: {
            taskId: id,
            orderId: this.$route.query.id,
            operation: taskStatus === '4004002' ? 'edit' : 'view',
          }
        })
      } else {
        // 大客户查询是否存在可批量操作的订单
        // if ([1,2,5].includes(this.detail.orderType)){
        //   const res = await deliveryServices.getBatchOrder({ orderId: this.detail.id, type:1 }) // type?
        //   if (res.length){
        //     this.deliverList = res
        //     this.showBatch = true
        //     return
        //   }
        // }
        this.onlineContractSend()
      }
    },
    onBatchSelect(selectIds){
      const orderIds = [...selectIds,this.orderId]
      this.$set(this.params,'orderIds',orderIds)
      // 请求提交
      this.requestSubmit()
    },
    onlineContractSend(){
      const vm = this
      orderServices.orderContractConfirm(this.$route.query.id,2).then((contractSignUrl)=>{
        wx.invoke(
          'shareAppMessage', {
            title: this.$t('交车确认书签署'), // 分享标题
            desc: '', // 分享描述
            link: contractSignUrl, // 分享链接
            imgUrl: '', // 分享封面
            enableIdTrans: 1, // 是否开启id转译，不填默认为0
          }, function (res) {
            if (res.err_msg == 'shareAppMessage:ok') {
              // vm.$toast.success(vm.$t('发送合同成功'))
            }
          }
        )
      }).catch(error=>{
        vm.$toast.fail('发送合同失败，请联系管理员')
      }).finally(()=>{
        vm.show = false
      })
    },
    vehicleLicense({ id, taskStatus }) {
      this.$router.push({
        name: 'vehicleLicense',
        query: {
          taskId: id,
          orderId: this.$route.query.id,
          operation: taskStatus === '4004002' ? 'edit' : 'view',
          taskStatus,
          deliveryDealerId: this.detail?.deliveryDealerId,
          customerType: this.detail?.customerType,
        }
      })
    },
    tempLicense({ id, taskStatus }) {
      this.$router.push({
        name: 'tempLicense',
        query: {
          taskId: id,
          orderId: this.$route.query.id,
          operation: taskStatus === '4004002' ? 'edit' : 'view',
          taskStatus
        }
      })
    },
    officialLicense({ id, taskStatus }) {
      this.$router.push({
        name: 'officialLicense',
        query: {
          taskId: id,
          orderId: this.$route.query.id,
          operation: taskStatus === '4004002' ? 'edit' : 'view',
          taskStatus
        }
      })
    },
    purchaseInsurance({ id, taskStatus }) {
      this.$router.push({
        name: 'purchaseInsurance',
        query: {
          taskId: id,
          orderId: this.$route.query.id,
          operation: taskStatus === '4004002' ? 'edit' : 'view',
          taskStatus
        }
      })
    },
    // 执行任务
    async deliveryExecution({ id, taskCode }) {
      this.$dialog.confirm({
        title: '',
        message: this.$t('请确认是否完成该任务 ？'),
        beforeClose: async (action, done)=>{
          done()
          if (action === 'confirm') {
            try {
              loading.showLoading()
              const params = {
                id,
                taskStatus: '4004003',
                executionJson: {
                  taskCode,
                },
              }
              const res = await deliveryServices.deliveryExecution(params)
              loading.hideLoading()
              if (res.code === 0) {
                this.$toast.success(this.$t('操作成功'))
                setTimeout(() => {
                  this.$emit('update')
                  this.getDeliverTask(this.detail.id)
                }, 1000)
              } else {
                this.$toast(res.msg)
              }
              
            } catch (error) {
              loading.hideLoading()
              this.$toast.fail(this.$t('操作失败'))
            } 
          }
        }
      })
    },
    onVehicleReady({ id, taskStatus }){
      this.$router.push({
        name: 'vehicleReady',
        query: {
          taskId: id,
          orderId: this.$route.query.id,
          operation: taskStatus === '4004003' ? 'view' : 'edit',
          taskStatus
        }
      })
    },
    // 提车确认书预览
    async onPreview(){
      // 预览
      const params = {
        orderId: this.$route.query.id,
        contractType: 2, // 1 购车 2交车
        backUrl: `${window.location.href}&token=${sessionStorage.getItem('Token')}&isBack=true`
      }
      orderServices.orderContract(params).then(res=>{
        // 查看交车合同
        if (!this.$isMobile) {
          window.open(res.contractViewUrl, 'myWindow')
        } else {
          window.location.href = res.contractViewUrl
        }
      })
    },
    // 判断任务是否完成
    isTaskOk({ taskCode,taskStatus }){
      let result = false
      if (taskCode!=='ORDER_PAY'){
        result = taskStatus==='4004003'
      } else if (taskCode==='ORDER_PAY'){
        result = (this.detail.installmentsFlag!==1 && this.detail.balancePayStatus==='4011003') || (this.detail.installmentsFlag===1 && this.detail.balancePayStatus==='4011003' && this.detaildetail?.financeStatus==='40050012')
      }
      return result
    },
    // 新保任务
    insurance({ id, }){
      // if (!this.detail?.invoice?.einvoiceUrl){
      //   this.$toast.fail(this.$t('请先完成车辆发票任务并上传发票'))
      //   return
      // }
      this.$router.push({
        path: '/brandInsurance',
        query: {
          taskId: id,
          orderId: this.$route.query.id,
          einvoiceUrl: this?.detail?.invoice?.einvoiceUrl
        }
      })
    }
  }

}
</script>

<style lang="less" scoped>
.concerns {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 16px 0 0;
  background: #f5f5f5;

  .item {
    display: flex;
    width: 100%;
    height: auto;

    .item-l {
      display: flex;
      flex-direction: column;
      width: 24px;
      margin-right: 16px;
      justify-content: center;
      align-items: center;

      .line {
        width: 2px;
        flex: 1;
        background: rgba(13, 23, 26, 0.35);
        border-radius: 0px 0px 0px 0px;
        margin: 10px 0;

        &.active {
          background: #0D171A;
        }
      }
    }

    .item-r {
      position: relative;
      flex: 1;
      min-height: 0;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      background: #fff;
      margin: 12px;
      margin-top: 0;
      padding: 0 12px;
      border-radius: 4px;
      
      &::before{
        position: absolute;
        width: 4px;
        height: 100%;
        background: #EED484;
        content: '';
        left: 0;
        top: 0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      .title {
        display: flex;
        height: 48px;
        align-items: center;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(13, 23, 26, 0.05);
        justify-content: space-between;
        font-size: 16px;
        position: relative;
        flex-shrink: 0;

        .yellowTxt {
          color: @yellow-text;
        }

        .necessaryFlag {
          position: relative;
          margin-left: 7px;
          padding-left: 7px;

        }

        .status {
          float: right;
          font-size: 14px;

          &.status_4004001 {
            color: #E4002C;
          }
          &.status_4004005 {
            color: #E4002C;
          }

          &.status_4004002 {
            color: rgba(13, 23, 26, 0.35);
          }

          &.status_4004003 {
            color: #029640;
          }

          &.status_4004004 {
            color: #FFD100;
          }
        }
      }

      .content {
        flex-shrink: 0;
        min-height: 0;
        box-sizing: border-box;
        padding: 10px 0;
        color: rgba(13, 23, 26, 0.45);
        font-size: 12px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .txt {
          flex: 1;
          word-break: break-all;
          flex-shrink: 0;
        }
        .btn-line{
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }

        .btn {
          min-height: 24px;
          padding: 0 4px;
          margin-top: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #EED484;
          color: @black;
          border-radius: 3px;
        }
      }
    }
    .success{
      &::before{
        background: #a9ea7a;
      }
    }
    
  }
}

.sign-popup {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  left: 50%;
  transform: translateX(-50%);
  .sign-type{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .btn-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      p {
        font-size: 16px;
        margin-bottom: 10px;
        color: rgba(13, 23, 26, 0.45);
      }
    }
  }
  .btn-item{
    width: 100%;
    margin-top: 20px;
  }
}
</style>
