<template>
    <div class="deliverTrack">
        <div v-for="(item, index) in deliverTrack" :key="index" class="item">
        <div class="item-l">
            <div class="time">
            <div class="day">{{ item.day }}</div>
            <div class="hours">
                <div>{{ `${item.year}/${item.month}` }}</div>
                <div>{{ item.date }}</div>
            </div>
            </div>
        </div>
        <div class="line"></div>
        <div class="item-r">
            <div class="title">
              <span>{{ item.eventName }}</span>
              <span v-if="item.eventCode === 'DELIVERY_PDI'">：{{ item.execJson.result === 1 ? '通过' : '未通过' }}</span>
            </div>
            <div v-if="item.eventCode !== 'DELIVERY_STORE_ASSIGN'" class="content">
                <span v-if="item.sysRemark">{{ item.sysRemark }}</span>
                <span v-if="item.sysRemark&&item.execUserName"> ｜ </span>
                <span v-if="item.execUserName">{{ item.execUserName }}</span>
            </div>
            <template v-if="item.eventCode === 'DELIVERY_STORE_ASSIGN'">
                <div class="content">{{ $t('厂端分配') }} ｜ {{ item.execUserName  }}</div>
                <div class="content">{{ $t('原交付店') }} : {{ item.execJson.orgDealerName  }}</div>
                <div class="content">{{ $t('新交付店') }} : {{ item.execJson.newDealerName }}</div>
              </template>
        </div>
        </div>
        <Empty v-if="deliverTrack.length == 0" :description="$t('暂无数据')"></Empty>
    </div>
</template>
<script>
import { getEventList } from '@/services/deliverEventService.js'
import loading from '@/utils/loading'
import dayjs from 'dayjs'
export default {
  data() {
    return {
      deliverTrack: [],
      queryMark: false,
    }
  },
  mounted() {
    this.getEventList()
  },
  methods: {
    async getEventList(){
      console.log(this.$route.query.id,'sss')
      const res = await getEventList({ orderId : this.$route.query.id })
      this.deliverTrack = res.map(item=>({
        ...item,
        year: dayjs(item.execTime).year(),
        month: dayjs(item.execTime).month()+1,
        day: dayjs(item.execTime).date(),
        date: dayjs(item.execTime).format('HH:mm'),
      }))
    //   this.deliverTrack = [
    //     {
    //       eventName:'非排产',
    //       execUserName: 'zs',
    //       sysRemark: '系统同步',
    //       year: '2024',
    //       month: '04',
    //       day: '22',
    //       date: '19:55'
    //     },
    //     {
    //       eventName:'交付单分配',
    //       //   execUserName: 'zs 22',
    //       sysRemark: '系统同步',
    //       year: '2024',
    //       month: '04',
    //       day: '22',
    //       date: '19:55'
    //     }
    //   ]
    }
    // async getTrack() {
    //   loading.showLoading()
    //   const res = await deliveryServices.deliveryStatusHis({ orderId : this.$route.query.id })
    //   this.queryMark = true
    //   this.deliverTrack = res.map((item) => ({
    //     ...item,
    //     year: dayjs(item.changeTime).year(),
    //     month: dayjs(item.changeTime).month(),
    //     day: dayjs(item.changeTime).date(),
    //     date: dayjs(item.changeTime).format('HH:mm'),
    //   })) || []
    //   loading.hideLoading()
    // }
  }
}
</script>
    
<style lang="less" scoped>
.deliverTrack{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 16px 12px 0;
    .item{
    display: flex;
    height: auto;
    padding: 8px 0;
    &:first-of-type {
        padding-top: 0;
    }
    &:last-of-type {
        padding-bottom: 0;
    }
    .item-l{
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: @black;
        width: 75px;
        margin-right: 16px;
        .time{
        display: flex;
        position: relative;
        .day{
            font-size: 24px;
            display: flex;
            margin-top: -2px;
            margin-right: 2px;
        }
        .hours{
            display: flex;
            flex-direction: column;
            font-size: 12px;
        }
        }
    }
    .item-r{
        display: flex;
        flex-direction: column;
        flex: 1;
        min-height: 63px;
        border-radius: 4px;
        background: #F4F8FE;
        padding: 12px;
        font-size: 12px;
        justify-content: space-between;
        .content{
        color: rgba(13,23,26,0.45);
        }
    }
    }
}
</style>
    