<template>
<div class="deliverInfo">
  <!-- <div class="deliverInfo-t">
    <div class="deliverInfo-t-l">
      <div class="deliverInfo-t-l-id">{{ detail.appOrderId }}</div>
      <div v-if="[1,0].includes(detail.installmentsFlag)" class="deliverInfo-t-l-status">{{ detail.installmentsFlag == 1 ? '分期' : '全款' }}</div>
    </div>
    <div class="deliverInfo-t-r">
      <div>{{ `${detail.carOrderMaterial?.seriesName} ${detail.carOrderMaterial?.modelName}` }}</div>
      <div v-if="detail.totalAmount" class="amount"><span content="¥"></span>{{ `${formatRMB(detail.totalAmount)}` }}</div>
    </div>
  </div> -->
  <DeliverTitle :dataSource="detail" :font="true"></DeliverTitle>
  <div class="deliverInfo-m">
    <div class="item deliverInfo-t-r">
      <div class="vehicle">{{ `${detail.carOrderMaterial?.seriesName} ${detail.carOrderMaterial?.modelName}` }}</div>
      <div v-if="detail.totalAmount" class="amount"><span content="¥"></span>{{ `${formatRMB(detail.totalAmount)}` }}</div>
    </div>
    <div class="item half">
      <div class="common-label-t">{{$t('车身颜色：')}}</div>
      <div class="common-label-c">{{ detail.carOrderMaterial?.colourName }}</div>
    </div>
    <div class="item half">
      <div class="common-label-t">{{$t('内饰颜色：')}}</div>
      <div class="common-label-c">{{ detail.carOrderMaterial?.interiorName }}</div>
    </div>
    <div class="item">
      <div class="common-label-t">{{$t('选装：')}}</div>
      <div class="common-label-c options">
        <div v-for="(optionsItem, index) in (detail.carOptionsList || [])" :key="index" class="options-t">{{ `${index+1}、${optionsItem.optionsName || ''}` }}</div>
      </div>
    </div>
    <div class="item">
      <div class="common-label-t">VIN：</div>
      <div class="common-label-c">{{ detail.vin }}</div>
      <div class="item-d" @click="goOrderDetail">
        {{$t('查看订单详情')}}
        <van-icon name="arrow" />
      </div>
    </div>
    <!-- <div class="item">
      <div class="common-label-t">尾款状态：</div>
      <div class="common-label-c">{{ detail.balancePayStatus | codeFormat }}</div>
    </div>
    <div class="item">
      <div class="common-label-t">发票状态：</div>
      <div class="common-label-c">{{ detail.invoiceStatus | codeFormat }}</div>
    </div>
    <div class="item">
      <div class="common-label-t">保险状态：</div>
      <div class="common-label-c">{{ detail.insureStatus | codeFormat }}</div>
      <div class="item-d" @click="goOrderDetail">
        查看订单详情
        <van-icon name="arrow" />
      </div>
    </div> -->
  </div>
</div>
</template>
<script>
import DeliverTitle from './deliver-title'
export default {
  name: 'deliverInfo',
  components: { DeliverTitle },
  props: {
    detail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {

    }
  },
  methods: {
    goOrderDetail() {
      this.$router.push({
        path: '/order-detail',
        query: {
          id: this.detail.id
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.deliverInfo{
  .deliverInfo-t{
    display: flex;
    flex-direction: column;
    height: 52px;
    font-size: 16px;
    .deliverInfo-t-l{
      display: flex;
      height: 16px;
      line-height: 16px;
      margin-bottom: 8px;
      .deliverInfo-t-l-id{
        max-width: calc(100% - 48px);
        // flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .deliverInfo-t-l-status{
        width: 40px;
        margin-left: 8px;
        padding-left: 8px;
        position: relative;
        color: #B9921A;
        box-sizing: border-box;
      }
      .deliverInfo-t-l-status::before{
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 1px;
        height: 70%;
        background-color: #D9D9D9;
        transform: translateY(-50%);
      }
    }
    .deliverInfo-t-r{
      display: flex;
      justify-content: space-between;
      color: #0D171A;
      line-height: 16px;
      font-size: 13px;
      .amount{
        color: #E4002C;
      }
    }
  }
  .deliverInfo-m{
    display: flex;
    flex-wrap: wrap;
    // border-top: 1px solid rgba(13,23,26,0.05);
    .item{
      display: flex;
      width: 100%;
      &.deliverInfo-t-r{
        display: flex;
        justify-content: space-between;
        color: #0D171A;
        line-height: 16px;
        font-size: 13px;
        .vehicle{
          font-size: 16px;
        }
        .amount{
          color: #E4002C;
        }
      }
      &:not(:last-of-type){
        margin-bottom: 8px;
      }
      &.half{
        width: 50%;
      }
      .item-d{
        width: 85px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        color: rgba(13,23,26,0.45);
      }
    }
  }
  .deliverInfo-b{
    display: flex;
  }
}
</style>
