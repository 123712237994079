<template>
    <div>
        <div v-if="policyInfo.id">
            <van-cell :title="$t('任务号')" :value="policyInfo.taskNo" />
            <van-cell :title="$t('任务状态')">
            {{ statusInfo[policyInfo.status] }}
            </van-cell>
            <template v-if="policyInfo.status != 5">
            <van-cell :border="false">
                <template #title>
                <div :class="{ 'custom-cell-title': !!policyDetails.length }">
                    {{ $t("保险信息") }}
                </div>
                </template>
            </van-cell>

            <div
                v-if="policyDetails.length"
                style="background-color: #F5F4F5;padding-top: 12px;overflow: hidden;"
            >
                <van-cell-group
                v-for="(item, index) in policyDetails"
                :key="index"
                style="margin: 0 12px 12px;"
                inset
                >
                <van-cell :title="$t('险种类型code')" class="custom-cell">
                    <span v-if="item.riskKind">
                    {{ item.riskKind.code }}
                    </span>
                </van-cell>
                <van-cell class="custom-cell" :title="$t('险种类型名称')">
                    <span v-if="item.riskKind">
                    {{ item.riskKind.name }}
                    </span></van-cell
                >
                <van-cell
                    class="custom-cell"
                    :title="$t('保单号')"
                    :value="item.policyNo"
                />
                <van-cell
                    class="custom-cell"
                    :title="$t('生效时间')"
                    :value="item.effectiveTime"
                />
                <van-cell
                    class="custom-cell"
                    :title="$t('失效时间')"
                    :value="item.expireTime"
                />
                <van-cell
                    class="custom-cell"
                    :title="$t('保单确认时间/承保时间')"
                    :value="item.confirmTime"
                />
                <van-cell
                    class="custom-cell"
                    :title="$t('保费金额')"
                >
                    <span v-if="item.premium" content="¥">
                    {{ formatRMB(item.premium) }}
                    </span>
                </van-cell>
                </van-cell-group>
            </div>
            </template>
            <van-cell
            v-if="policyInfo.status == 5"
            :title="$t('战败原因')"
            :value="policyInfo.reason"
            />
            <van-cell :title="$t('创建人')" :value="policyInfo.policyCreator" />
            <van-cell :title="$t('创建时间')" :value="policyInfo.createTime" />
        </div>
        <Empty v-else style="background-color: #F5F4F5;margin-top: 10px"></Empty>
    </div>
</template>
<script>
export default {
  props:{
    policyInfo:{
      type: Object,
      default: ()=>({})
    }
  },
  data(){
    return {
      statusInfo: {
        1: this.$t('待分配'),
        2: this.$t('已分配待处理'),
        3: this.$t('处理中'),
        4: this.$t('承保'),
        5: this.$t('战败'),
      },
    }
  },
  computed: {
    policyDetails() {
      return this.policyInfo.policyDetails || []
    },
  }
}
</script>