<template>
    <div class="order-pay">
        <div v-for="field in fieldList" :key="field.key" class="row">
            <div class="label">{{ field.label }}：</div>
            <div class="value">
                <span v-if="![undefined,null,''].includes(getValue(field.key))" content="¥"></span>
                <span>{{ ![undefined,null,''].includes(getValue(field.key)) ? getValue(field.key) : '--'   }}</span>
            </div>
        </div>
        <div class="pay-status m20">
            <div class="label">{{ $t('支付状态') }}：{{ details.balancePayStatus | codeFormat }}</div>
            <!-- <div class="btn-line">
              <div v-if="details.balancePayStatus!='4011003'&&isDeliverSalesOwner" class="btn" @click="isShow=true">
                  <span>{{ $t('收款账号') }}</span>
              </div>
            </div> -->
        </div>
        <div>   
          <div class="account">
            <span>{{ $t('收款账号') }}</span>
            <span @click="onCopy">{{ $t('复制信息') }}</span>
          </div>
          <div v-for="field in fieldList3" :key="field.key" class="line">
              <div class="field">{{ field.label }}：</div>
              <div class="content">
                  <span>{{ getValue(field.key) || '--' }}</span>
              </div>
          </div>
        </div>
        <!-- 分期才展示 -->
        <template v-if="details.installmentsFlag===1">
            <div v-for="field in fieldList2"  :key="field.key" class="row">
                <div class="label">{{ field.label }}：</div>
                <div v-if="field.key === 'financeStatus'" class="value">
                  <!-- v-if="details?.finance" -->
                    <span>{{  details.financeStatus | codeFormat }}</span>
                    <!-- <span v-else>{{ $t('未申请') }}</span> -->
                </div>
                <div v-else class="value">
                  <span v-if="details?.finance">{{ details?.finance[field.key] || '--' }}</span>
                </div>
            </div>
            <!-- 金融状态为未申请时展示 -->
            <div v-if="!details.finance" style="margin-top: 16px;">{{ $t('客户订单为分期订单，还未签署金融合同，请使用工作台中的金融计算器协助客户尽快完成金融合同办理。') }}</div>
            <!-- 金融状态为未放款则可以撤销申请 -->
            <div v-if="isDeliverSalesOwner&&details?.finance?.id" class="pay-status">
              <div class="label"></div>
              <div class="btn-line">
                <div class="btn" @click="onClick">{{ $t('撤销金融申请') }}</div>
              </div>
          </div>
            
        </template>
        
        <!-- 收款账户 -->
        <van-dialog v-model="isShow"  :show-cancel-button="true" :cancel-button-text="$t('关闭')" :show-confirm-button="false">
          <div slot="title" class="header">
            <span>{{ $t('收款账号') }}</span>
            <span @click="onCopy">{{ $t('复制信息') }}</span>
          </div>  
          <div v-for="field in fieldList3" :key="field.key" class="line">
                <div class="field">{{ field.label }}：</div>
                <div class="content">
                    <span>{{ getValue(field.key) || '--' }}</span>
                </div>
            </div>
        </van-dialog>
    </div>
</template>
<script>
import loading from '@/utils/loading'
import deliveryServices from '@/services/deliveryServices.js'
export default {
  props:{
    // 交车详情
    details:{
      type:Object,
      default: ()=>({})
    },
    isDeliverSalesOwner:{
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      fieldList: [
        {
          label: this.$t('总金额'),
          key: 'totalAmount'
        },
        {
          label: this.$t('定金金额'),
          key: 'depositAmount'
        },
        {
          label: this.$t('尾款金额'),
          key: 'balanceAmount'
        },
        {
          label: this.$t('已支付金额'),
          key: 'paidAmountList', 
        },
        {
          label: this.$t('待支付金额'),
          key: 'toBePaidAmount', 
        },
      ],
      fieldList2: [
        {
          label: this.$t('金融状态'),
          key: 'financeStatus'
        },
        {
          label: this.$t('首付金额'),
          key: 'downPaymentAmount'
        },
        {
          label: this.$t('贷款金额'),
          key: 'loanAmount'
        }
      ],
      isShow: false,
      fieldList3: [
        {
          label: this.$t('户名'),
          key: 'acctName',
        },
        {
          label: this.$t('开户行'),
          key: 'acctBankName',
        },
        {
          label: this.$t('收款账号'),
          key: 'acctBankNo',
        }
      ]
    }
  },
  methods: {
    onCopy(){
      if (wx?.setClipboardData){
        const copyText = this.fieldList3.map(item=>this.getValue(item.key) || '--')
        wx.setClipboardData({
          data: copyText.join(' '), // 需要复制的数据
          success: function(res) {
          }
        })
      }
    },
    onClick(){
      this.$dialog.confirm({
        title: this.$t('确认撤销当前订单的金融申请'),
        message: this.$t('请确认订单信息是否准确，金融订单将在购车人确认后进行撤销 ？'),
        confirmButtonText: this.$t('确认'),
        cancelButtonText: this.$t('取消'),
        beforeClose: async (action, done)=>{
          done()
          if (action === 'confirm') {
            try {
              loading.showLoading()
              await deliveryServices.cancelFinance( this.details.finance.id)
              loading.hideLoading()
              this.$toast.success(this.$t('请求成功'))
            } catch ({ message }) {
              loading.hideLoading()
              // this.$toast.fail(message)
            } 
          }
        }
      })
    },
    getValue(fieldKey){
      if (fieldKey === 'paidAmountList'){
        return (this.details?.extend?.paidAmountList || []).reduce((sum,item)=>sum + Number(item.tranAmt),0)
      }
      return this.details[fieldKey] || (this.details?.extend || {})[fieldKey]
    }
  }
}
</script>
<style lang="less" scoped>
.order-pay{
    width: 100%;
    .row{
        display: flex;
        .value{
            display: flex;
            align-items: center
        }
    }
    .pay-status{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .btn-line{
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }
    }
    .account {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(13, 23, 26, 0.05);
      justify-content: space-between;
      position: relative;
      flex-shrink: 0;
      &>span:first-child{
        color: @black;
        position: relative;
        display: inline-block;
      }
      &>span:last-child{
        color: #EED484;;
        display: inline-block;
        justify-content: end;
      }
    }
    .btn{
        max-width: max-content;
        height: 24px;
        margin-top: 16px;
        padding: 0 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #EED484;
        color: #0D171A;
        cursor: pointer;
        border-radius: 3px;
    }
    .m20{
        margin: 16px 0;
    }
    .header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      &>span:first-child{
        color: @black;
        position: relative;
        padding-left: 8px;
        display: inline-block;
      }
      &>span:last-child{
        color: #EED484;;
      }
    }
    .line{
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
    }
}
</style>