<template>
<div class="folloTrack">
  <Empty v-if="tripDetails?.trips.length == 0" :description="$t('暂无数据')"></Empty>
  <Trips v-else :details="tripDetails"/>
</div>
</template>
<script>
import Trips from '@/modules/clue/component/trips/index.vue'
import clueServices from '@/services/clueServices'
import loading from '@/utils/loading'
export default {
  components:{ Trips },
  props:{
    detail:{
      type: Object,
      default: ()=>({})
    }
  },
  data() {
    return {
      tripDetails: {
        trips: []
      },
      queryMark: false,
      id: ''
    }
  },
  computed: {
  },
  watch: {

  },
  mounted() {
    this.getTrips()
  },
  methods: {
    // 获取轨迹
    async getTrips(){
      const params = { 
        id: this.detail.leadId,
        orderId: this.detail.id,
        type: 'DELIVERY'
      }
      loading.showLoading()
      const res = await clueServices.getTrips(params)
      loading.hideLoading()
      this.queryMark = true
      this.tripDetails = res
    },
  }
}
</script>

<style lang="less" scoped>
.folloTrack{
  padding: 16px 0 0;
}
</style>
