<template>
    <van-tabs v-model="activeName" :ellipsis="false">
      <van-tab v-for="(item, index) in tabList" :key="index" :title="item.title" :name="item.componentName">
        <component :is="item.componentName" v-if="activeName === item.componentName"
            :id="detail.leadId"
            :isDeliverSalesOwner="isDeliverSalesOwner" class="scrollBlock" :detail="detail"></component>
      </van-tab>
    </van-tabs>
</template>
<script>
import DeliverTrack from './DeliverTrack'
import FolloTrack from './FolloTrack'
let vm
export default {
  components:{
    DeliverTrack,FolloTrack
  },
  props:{
    detail:{
      type:Object,
      default:()=>({})
    },
    isDeliverSalesOwner: {
      type:Boolean,
      default: true
    }
  },
  data(){
    return {
      activeName: 'DeliverTrack',
      tabList: [
        { componentName: 'DeliverTrack', title: vm.$t('交付状态') },
        { componentName: 'FolloTrack', title: vm.$t('客户跟进') }
      ]
    }
  },
  beforeCreate(){
    vm = this
  },
}
</script>