<template>
<div class="orderTitle">
  <div class="orderTitle-l">
      <div :class="['orderTitle-l-id', font ? 'blod' : '']">{{ dataSource.orderType===3?dataSource.appOrderId:dataSource.dcosOrderId }}</div>
      <div style="display: flex; align-items: center;">
        <div v-if="[1,0].includes(dataSource.installmentsFlag)" class="orderTitle-l-status">{{ dataSource.installmentsFlag == 1 ? $t('分期') : $t('全款') }}</div>
        <div v-if="[1,0].includes(dataSource.installmentsFlag) && dataSource.bigType" class="gap"></div>
        <div v-if="dataSource.bigType" class="bigTye">{{ dataSource.bigType == 1 ? $t('厂端大客户') : $t('代理商大客户') }}</div>
      </div>
  </div>
  <div class="orderTitle-r">
    <div>{{$t('分配时间')}}</div>
    <div>{{ dataSource.deliveryAssignTime | timeFormat('YYYY-MM-DD')}}</div>
  </div>
</div>
</template>
<script>
export default {
  props: {
    dataSource: {
      type: Object,
      default: () => ({})
    },
    font: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
    }
  },
}
</script>
<style lang="less" scoped>
.orderTitle{
  padding-bottom: 8px;
  border-bottom: 1px solid #F3F3F4;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  .orderTitle-l{
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    .orderTitle-l-id{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: noWrap;
      margin-bottom: 2px;
      &.blod {
        font-size: 16px;
      }
    }
    .orderTitle-l-status{
      color: #B9921A;
    }
    .gap{
      width: 1px;
      margin: 0 8px;
      height: 12px;
      background-color: #D9D9D9;
    }
  }
  .orderTitle-r{
    width: 100px;
    color: rgba(13,23,26,0.45);
    text-align: right;
    display: flex;
    flex-direction: column;
  }
}
</style>
