<template>
  <div class="orderInfo">
    <van-tabs v-model="active" color="#B9921A" type="card" @change="onChange">
      <van-tab :title="$t('订单')"></van-tab>
      <van-tab :title="$t('金融')"></van-tab>
      <van-tab :title="$t('保险')"></van-tab>
    </van-tabs>
    <template v-if="active == 0">
      <van-collapse v-model="activeNames">
        <van-collapse-item name="订单明细" :title="$t('订单明细')">
          <van-cell :title="$t('特殊资源备注')">
            <template #default>
              <div class="specialRemarkUrl">
                <p>{{ orderDetail.specialRemark }}</p>
                <van-uploader
                  v-model="specialRemarkUrlList"
                  class="upload"
                  disabled
                  :deletable="false"
                  capture="camera"
                  accept="image/*"
                  :preview-options="{ closeable: true }"
                />
              </div>
            </template>
          </van-cell>
          <van-cell
            :title="$t('订单来源')"
            :value="orderDetail.orderSource"
          ></van-cell>
          <van-cell
            :title="$t('客户类型')"
            :value="orderDetail.customerType == 1 ? $t('个人') : $t('企业')"
          ></van-cell>
          <van-cell
            :title="$t('订单总金额')"
            :value="`¥${formatRMB(orderDetail.totalAmount)}`"
          >
            <span content="¥"></span>{{ formatRMB(orderDetail.totalAmount) }}
          </van-cell>
          <van-cell :title="$t('意向金')">
            <span
              v-if="formatRMB(orderDetail.intentionAmount)"
              content="¥"
            ></span
            >{{ formatRMB(orderDetail.intentionAmount) }}
          </van-cell>
          <van-cell :title="$t('定金')">
            <span v-if="formatRMB(orderDetail.depositAmount)" content="¥"></span
            >{{ formatRMB(orderDetail.depositAmount) }}
          </van-cell>
          <van-cell :title="$t('尾款')">
            <span v-if="formatRMB(orderDetail.balanceAmount)" content="¥"></span
            >{{ formatRMB(orderDetail.balanceAmount) }}
          </van-cell>
          <van-cell :title="$t('折扣金额')">
            <span
              v-if="formatRMB(orderDetail.discountAmount)"
              content="¥"
            ></span
            >{{ formatRMB(orderDetail.discountAmount) }}
          </van-cell>
          <van-cell :title="$t('实际金额')">
            <span v-if="formatRMB(orderDetail.actualAmount)" content="¥"></span
            >{{ formatRMB(orderDetail.actualAmount) }}
          </van-cell>
          <van-collapse v-model="activeNames">
            <van-collapse-item :name="1" :title="$t('优惠券折扣明细')">
              <template v-if="carOrderDiscountList.length > 0">
                <div v-for="(item, index) in carOrderDiscountList" :key="index">
                  <div class="content-t">{{ item.discountReason }}</div>
                  <van-field
                    rows="1"
                    autosize
                    type="textarea"
                    :value="item.discountRemark"
                    :placeholder="$t('折扣备注说明')"
                    class="content-c"
                  />
                  <div class="content-p">
                    <span content="¥"></span
                    >{{ `${formatRMB(item.discountAmount) || "--"}` }}
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="noDiscount">{{ $t("无折扣原因！") }}</div>
              </template>
            </van-collapse-item>
            <!-- <van-collapse-item :name="2" title="金融信息">
      <van-cell title="金融状态" :value="orderDetail.financeStatus"></van-cell>
      <van-cell title="金融方案" :value="''"></van-cell>
      <van-cell title="贷款金额" :value="''"></van-cell>
    </van-collapse-item> -->
          </van-collapse>
        </van-collapse-item>
        <van-collapse-item name="大定物料信息" :title="$t('大定物料信息')">
          <van-cell
            :title="$t('物料编码')"
            :value="carOrderMaterial.materialCode"
          />
          <van-cell
            :title="$t('车系编码')"
            :value="carOrderMaterial.seriesCode"
          />
          <van-cell
            :title="$t('车型编码')"
            :value="carOrderMaterial.modelCode"
          />
          <van-cell :title="$t('车型价格')">
            <span v-if="carOrderMaterial.modelPrice" content="¥">
              {{ formatRMB(carOrderMaterial.modelPrice) }}
            </span>
          </van-cell>
          <van-cell
            :title="$t('外观编码')"
            :value="carOrderMaterial.colourCode"
          />
          <van-cell :title="$t('外观价格')">
            <span v-if="carOrderMaterial.colourPrice" content="¥">
              {{ formatRMB(carOrderMaterial.colourPrice) }}
            </span>
          </van-cell>
          <van-cell
            :title="$t('内饰编码')"
            :value="carOrderMaterial.interiorCode"
          />
          <van-cell :title="$t('内饰价格')">
            <span v-if="carOrderMaterial.interiorPrice" content="¥">
              {{ formatRMB(carOrderMaterial.interiorPrice) }}
            </span>
          </van-cell>
          <van-cell :title="$t('数量')" :value="carOrderMaterial.quantity" />
          <van-cell :title="$t('总金额')">
            <span v-if="carOrderMaterial.entryPrice" content="¥">
              {{ formatRMB(carOrderMaterial.entryPrice) }}
            </span>
          </van-cell>
        </van-collapse-item>
        <van-collapse-item name="选装明细" :title="$t('选装明细')">
          <div
            v-if="carOptionsList.length"
            style="background-color: #F5F4F5;padding-top: 12px;overflow: hidden;"
          >
            <van-cell-group
              v-for="(item, index) in carOptionsList"
              :key="index"
              style="margin: 0 12px 12px;"
              inset
            >
              <van-cell
                class="custom-cell"
                :title="$t('选装编码')"
                :value="item.optionsCode"
              />
              <van-cell class="custom-cell" :title="$t('选装价格')">
                <span v-if="item.optionsPrice" content="¥">
                  {{ formatRMB(item.optionsPrice) }}
                </span>
              </van-cell>
            </van-cell-group>
          </div>
          <Empty v-else style="background-color: #F5F4F5"></Empty>
        </van-collapse-item>
        <!-- <van-collapse-item name="权益明细" :title="$t('权益明细')">
          <van-cell :title="$t('权益类型')" :value="policyInfo.taskNo" />
          <van-cell :title="$t('权益名称')" :value="policyInfo.taskNo" />
        </van-collapse-item>
        <van-collapse-item name="卡券明细" :title="$t('卡券明细')">
          <van-cell :title="$t('卡券sn码')" :value="policyInfo.taskNo" />
          <van-cell :title="$t('卡券折扣金额')" :value="policyInfo.taskNo" />
        </van-collapse-item> -->
      </van-collapse>
    </template>
    <template v-else-if="active == 1">
      <!-- 购车方式=分期 并且金融状态!=金融未申请 -->
      <Finance
        v-if="orderDetail.finance && orderDetail.installmentsFlag === 1 && orderDetail.financeStatus != '4005000'"
        :orderDetail="{ ...orderDetail, orderFinance: orderDetail.finance }"
      >
      </Finance>
      <Empty
        v-else
        :description="$t('无金融信息')"
        style="background-color: #F5F4F5;margin-top: 10px"
      ></Empty>
    </template>
    <template v-else-if="active == 2">
      <Insurance :policyInfo="policyInfo"/>
      <!-- <div v-if="policyInfo.id">
        <van-cell :title="$t('任务号')" :value="policyInfo.taskNo" />
        <van-cell :title="$t('任务状态')">
          {{ statusInfo[policyInfo.status] }}
        </van-cell>
        <template v-if="policyInfo.status != 5">
          <van-cell :border="false">
            <template #title>
              <div :class="{ 'custom-cell-title': !!policyDetails.length }">
                {{ $t("保险信息") }}
              </div>
            </template>
          </van-cell>

          <div
            v-if="policyDetails.length"
            style="background-color: #F5F4F5;padding-top: 12px;overflow: hidden;"
          >
            <van-cell-group
              v-for="(item, index) in policyDetails"
              :key="index"
              style="margin: 0 12px 12px;"
              inset
            >
              <van-cell :title="$t('险种类型code')" class="custom-cell">
                <span v-if="item.riskKind">
                  {{ item.riskKind.code }}
                </span>
              </van-cell>
              <van-cell class="custom-cell" :title="$t('险种类型名称')">
                <span v-if="item.riskKind">
                  {{ item.riskKind.name }}
                </span></van-cell
              >
              <van-cell
                class="custom-cell"
                :title="$t('保单号')"
                :value="item.policyNo"
              />
              <van-cell
                class="custom-cell"
                :title="$t('生效时间')"
                :value="item.effectiveTime"
              />
              <van-cell
                class="custom-cell"
                :title="$t('失效时间')"
                :value="item.expireTime"
              />
              <van-cell
                class="custom-cell"
                :title="$t('保单确认时间/承保时间')"
                :value="item.confirmTime"
              />
              <van-cell
                class="custom-cell"
                :title="$t('保费金额')"
              >
                <span v-if="item.premium" content="¥">
                  {{ formatRMB(item.premium) }}
                </span>
              </van-cell>
            </van-cell-group>
          </div>
        </template>
        <van-cell
          v-if="policyInfo.status == 5"
          :title="$t('战败原因')"
          :value="policyInfo.reason"
        />
        <van-cell :title="$t('创建人')" :value="policyInfo.policyCreator" />
        <van-cell :title="$t('创建时间')" :value="policyInfo.createTime" />
      </div>
      <Empty v-else style="background-color: #F5F4F5;margin-top: 10px"></Empty> -->
    </template>
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid'
import deliveryServices from '@/services/deliveryServices.js'
import Finance from '@/modules/order/component/finance.vue'
import Insurance from './insurance.vue'

export default {
  name: 'orderInfo',
  components: {
    Finance,Insurance
  },
  props: {
    orderDetail: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      statusInfo: {
        1: this.$t('待分配'),
        2: this.$t('已分配待处理'),
        3: this.$t('处理中'),
        4: this.$t('承保'),
        5: this.$t('战败'),
      },
      active: 0,
      policyInfo: {},
      activeNames: [1, 2, '订单明细', '大定物料信息', '选装明细'],
      PolicyAtiveNames: ['1'],
    }
  },
  computed: {
    policyDetails() {
      return this.policyInfo.policyDetails || []
    },
    carOrderDiscountList() {
      return this.orderDetail.carOrderDiscountList || []
    },
    carOrderMaterial() {
      return this.orderDetail.carOrderMaterial || {}
    },
    carOptionsList() {
      return this.orderDetail.carOptionsList || []
    },
    specialRemarkUrlList() {
      let arr = []
      let list = this.orderDetail.specialRemarkUrl?.split(',') || []
      list.forEach((item) => {
        arr.push({
          uuid: uuidv4(),
          cosUrl: item,
          url: item,
          status: 'done',
        })
      })
      return arr
    },
  },
  methods: {
    onChange(value) {
      if (value == 2) {
        deliveryServices
          .policyDetail({ orderId: this.orderDetail.id })
          .then((res) => {
            this.$set(this, 'policyInfo', res)
          })
      }
    },
  },
}
</script>
<style lang="less" scoped>
.custom-cell-title {
  display: flex;
  align-items: center;
  &::before {
    content: "";
    width: 2px;
    height: 12px;
    background: #0040c5;
    margin-right: 6px;
  }
}
.custom-cell {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.orderInfo {
  .specialRemarkUrl {
    display: flex;
    flex-direction: column;
    p {
      margin-bottom: 10px;
    }
    /deep/ .van-uploader__wrapper--disabled {
      opacity: 1;
    }
    /deep/ .van-uploader__upload {
      display: none;
    }
  }
  /deep/ .van-cell {
    padding: 10px 0;
    &::after {
      width: 100%;
      left: 0;
    }
  }
  /deep/ .van-collapse {
    &::after {
      display: none;
    }
    .van-collapse-item--border::after {
      display: none;
    }
    .van-collapse-item {
      .van-collapse-item__title {
        .van-cell__title {
          padding-left: 8px;
          font-size: 16px;
          position: relative;
          &::before {
            content: " ";
            left: 0;
            position: absolute;
            top: calc(50% - 6px);
            width: 2px;
            height: 12px;
            background: #0040c5;
            border-radius: 0px 0px 0px 0px;
          }
        }
      }
      .van-collapse-item__content {
        padding: 0;
        .content-t {
          color: #0d171a;
          font-size: 14px;
          height: 40px;
          line-height: 40px;
        }
        .content-c {
          padding: 0;
          &.van-cell::after {
            display: none;
          }
          .van-field__body {
            .van-field__control {
              color: rgba(13, 23, 26, 0.45);
            }
          }
        }
        .content-p {
          width: 100%;
          min-height: 20px;
          line-height: 20px;
          text-align: right;
          color: rgba(13, 23, 26, 0.45);
          padding-bottom: 16px;
          position: relative;
          &::after {
            width: 100%;
            position: absolute;
            box-sizing: border-box;
            content: " ";
            pointer-events: none;
            right: 16px;
            bottom: 0;
            left: 0px;
            border-bottom: 1px solid #ebedf0;
            -webkit-transform: scaleY(0.5);
            transform: scaleY(0.5);
          }
        }
        .noDiscount {
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
</style>
